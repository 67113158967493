import MarkdownView from 'react-showdown';
import { useGetSlideQuery } from 'src/api/SlideApi';


export const RenderMarkdownUnity = ({ slideId }: { slideId: number }) => {

    const {
        data: slide,
        isError: isErrorLoadingSlide,
        refetch: refetchSlide,
    } = useGetSlideQuery(
        { slideId },
        { refetchOnMountOrArgChange: true },
    );

    return (slide ?
        <div >
            <MarkdownView markdown={slide.content} options={{ tables: true, emoji: true }}></MarkdownView>
        </div> : <></>
    );
};

