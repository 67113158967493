import React, { useContext } from "react";
import Button from "react-bootstrap/Button";

import { Action, Subjects } from "src/api/Permissions";

import { useGetAllActivitiesQuery } from "src/api/ActivityApi";
import { Activity } from "src/api/generated.api";
import { AbilityContext, Can } from "../../casl/Can";
import TableWithPagination from "src/components/tables/TableWithPagination";
import { LangContext } from "src/lang/lang";
import { subject } from "@casl/ability";
import { teamContext } from "../teams/context/team-context-provider";
import moment from "moment";

interface ActivitySelectorProps {
    onSelected?: (activity: Activity) => void;
    teamId?: string;
}

export function ActivitySelector({
    onSelected,
    teamId,
}: ActivitySelectorProps) {
    const ability = useContext(AbilityContext);
    const { currentTeam } = useContext(teamContext);
    const { ObjectNames, Sentences } = useContext(LangContext);
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const { data: activities, isLoading } = useGetAllActivitiesQuery(
        { limit: pageSize, offset: pageIndex * pageSize, filter, sort, teamId: currentTeam ? currentTeam.id : "", showPublicObjects: true },
        { refetchOnMountOrArgChange: true },
    );

    const columns = [
        {
            Header: ObjectNames.activity.en,
            width: undefined,
            Cell: ({ row }: { row: { original: Activity } }) => (
                <div>{row.original.name}</div>
            ),
        },
        {
            Header: "Created at",
            width: 200,
            Cell: ({ row }: { row: { original: Activity } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 100,
            Cell: ({ row }: { row: { original: Activity } }) => (
                <Can
                    I={Action.Read}
                    this={subject(Subjects.Activities, { ...row.original })}
                >
                    <Button
                        onClick={() => {
                            if (onSelected) onSelected(row.original);
                        }}
                    >
                        {Sentences.select.en}
                    </Button>
                </Can>
            ),
        },
    ];

    return (
        <div>
            {ability.can(Action.Read, Subjects.Activities) ? (
                <TableWithPagination
                    fetchData={(
                        pageIndexToFetch: number,
                        pageSizeToFetch: number,
                        filterToFetch: string,
                        sortToFetch: string
                    ) => {
                        setPageIndex(pageIndexToFetch);
                        setPageSize(pageSizeToFetch);
                        setFilter(filterToFetch);
                        setSort(sortToFetch);
                    }}
                    loading={isLoading}
                    columns={columns}
                    data={activities?.results ?? []}
                    itemCount={activities?.total || 0}
                    enableFiltering={true}
                    sortableProps={[
                        { name: 'Slide Deck', accessor: 'name' },
                        { name: 'Created at', accessor: 'createdAt' }
                    ]}
                />
            ) : (
                Sentences.notAllowedToListActivities.en
            )}
        </div>
    );
}
