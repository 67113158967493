import TableWithPagination from "../../components/tables/TableWithPagination";
import React, { useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";
import {
    useCreateTerrainMutation,
    useDeleteTerrainMutation,
    useFindAllTerrainsQuery,
} from "src/api/TerrainApi";
import { CreateTerrainDto, TerrainSummaryDto } from "../../api/generated.api";
import { Can } from "../../casl/Can";
import { Formik, FormikHelpers } from "formik";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { LangContext } from "src/lang/lang";
import { Column } from "react-table";
import { teamContext } from "../teams/context/team-context-provider";
import moment from "moment";
import RoutesDef from "src/routes/RoutesDef";
import TableWithPaginationUnity from "src/components/tables/TableWithPagination.unity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faSyncAlt } from "@fortawesome/free-solid-svg-icons";


export function TerrainsUnity() {
    const { ObjectNames, Sentences } = useContext(LangContext);

    const { currentTeam } = useContext(teamContext);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(0);

    const navigate = useNavigate();

    const terrainsQueryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        showPublicObjects: true
    }

    const {
        data: terrains,
        isLoading,
        isError,
        error,
        refetch
    } = useFindAllTerrainsQuery(terrainsQueryProps);
    const [
        deleteTerrain,
        { isError: deleteTerrainIsError, error: deleteTerrainError },
    ] = useDeleteTerrainMutation();
    const [
        createTerrain,
        { isError: createTerrainIsError, error: createTerrainError },
    ] = useCreateTerrainMutation();

    const onCreate = async (
        values: CreateTerrainDto,
        formikBag: FormikHelpers<CreateTerrainDto>,
    ) => {
        try {
            const terrain = await createTerrain({
                createTerrainDto: values,
            }).unwrap();
            navigate(`/terrains/${terrain.id}`);
        } catch (e) {
            try {
                const errors = convertApiErrorsToFormikErrors(e);
                formikBag.setErrors(errors);
            } catch (fe) {
                setErrorMessage(JSON.stringify(e));
            }
        }
    };

    useEffect(() => {
        const isErr = isError || deleteTerrainIsError;
        const err = [error, deleteTerrainError].filter((v) => v !== undefined);
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [isError, error, deleteTerrainIsError, deleteTerrainError]);

    const columns: Column<TerrainSummaryDto>[] = [
        {
            Header: 'Name',
            width: undefined,
            accessor: "name"
        },
        {
            Header: "Created at",
            width: 280,
            Cell: ({ row }: { row: { original: TerrainSummaryDto } }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 100,
            Cell: ({ row }: { row: { original: TerrainSummaryDto } }) => (
                <div>
                    <Button href={RoutesDef.UNITY_EDIT_SCENE + "/" + row.original.id}>Edit</Button>
                </div>
            ),
        },
    ];

    const refresh = () => {
        refetch()
    }

    return (

        <div>
            <Link className="nav-link mt-4" to={RoutesDef.UNITY_HOME} ><FontAwesomeIcon className="mr-2" icon={faBackward} /> Go back</Link>

            <div className="section mt-0">
                <h1>Edit scene</h1>
            </div>
            <div className="section">
                <Button className="" onClick={refresh}>
                    <FontAwesomeIcon icon={faSyncAlt} className="mr-1" /> Refresh list
                </Button>
                <TableWithPaginationUnity
                    fetchData={(
                        pageIndexToFetch: number,
                        pageSizeToFetch: number,
                        filterToFetch: string,
                        sortToFetch: string
                    ) => {
                        setPageIndex(pageIndexToFetch);
                        setPageSize(pageSizeToFetch);
                        setFilter(filterToFetch);
                        setSort(sortToFetch);
                    }}
                    loading={isLoading}
                    columns={columns}
                    data={terrains?.results ?? []}
                    itemCount={terrains?.total ?? 0}
                    enableFiltering={true}
                    sortableProps={[
                        { name: 'Name', accessor: 'name' },

                        { name: 'Created at', accessor: 'createdAt' }
                    ]}
                />

            </div>
        </div>

    );
}
