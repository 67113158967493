import { Subjects } from "./Permissions";
import {
    DocumentAclTagList,
    invalidateDocumentAclEntryTag,
    provideDocumentAclTags,
} from "./document-access-control.api";
import { api as generatedApi } from "./generated.api";

export const LAYER_TAG = "Layer" as const;

export const LayerApi = generatedApi.enhanceEndpoints({
    addTagTypes: [LAYER_TAG, ...DocumentAclTagList],
    endpoints: {
        layerControllerFindAll: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                        ...result.results.map(({ id }) => ({
                            type: LAYER_TAG,
                            id,
                        })),
                        LAYER_TAG,
                    ]
                    : [],
        },
        layerControllerFindAllWithRelations: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                        ...result.results.map(({ id }) => ({
                            type: LAYER_TAG,
                            id,
                        })),
                        LAYER_TAG,
                    ]
                    : [],
        },
        layerControllerFindOne: {
            providesTags: (result, error, arg) =>
                result
                    ? [
                        { type: LAYER_TAG, id: result.id },
                        LAYER_TAG,
                        ...provideDocumentAclTags(
                            result,
                            Subjects.TerrainLayer,
                        ),
                    ]
                    : [],
        },
        layerControllerRemove: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result ? [{ type: LAYER_TAG, id: result.id }, LAYER_TAG] : [];
        },
        layerControllerForceRemove: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result ? [{ type: LAYER_TAG, id: result.id }, LAYER_TAG] : [];
        },
        layerControllerUpdate: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result ? [{ type: LAYER_TAG, id: result.id }, LAYER_TAG] : [];
        },
        layerControllerCreate: (endpoint) => {
            endpoint.invalidatesTags = (result) =>
                result ? [{ type: LAYER_TAG, id: result.id }, LAYER_TAG] : [];
        },
        layerControllerAddUser: {
            invalidatesTags: invalidateDocumentAclEntryTag,
        },
        layerControllerChangeTeam: {
            invalidatesTags: (result, error, arg) => [
                { type: LAYER_TAG, id: parseInt(arg.id) },
            ],
        },
        layerControllerChangeVisibility: {
            invalidatesTags: (result, error, arg) => [
                { type: LAYER_TAG, id: parseInt(arg.id) },
            ],
        },
    },
});

export const {
    useLayerControllerFindAllQuery: useGetAllLayersQuery,
    useLayerControllerFindAllWithRelationsQuery: useGetAllLayersWithRelationsQuery,
    useLayerControllerFindOneQuery: useGetLayerQuery,
    useLayerControllerCreateMutation: useCreateLayerMutation,
    useLayerControllerUpdateMutation: useUpdateLayerMutation,
    useLayerControllerRemoveMutation: useDeleteLayerMutation,
    useLayerControllerForceRemoveMutation: useForceDeleteLayerMutation,
    useLayerControllerAddUserMutation: useLayerAddUser,
    useLayerControllerChangeTeamMutation: useChangeTeam,
    useLayerControllerChangeVisibilityMutation: useChangeVisibility,
} = LayerApi;
