import { FC, useMemo, useState } from "react";
import { Badge, Button, Col, Row, ToggleButton } from "react-bootstrap";
import {
    useCompleteTerrainModelFileUpload,
    useCreateFileRevisionForTerrainModelMutation,
    useDeleteFileRevisionForTerrainModelMutation,
    useFindRevsForTerrainModelQuery,
    useSetCurrentFileRevisionForTerrainModelMutation,
} from "src/api/TerrainModelApi";
import { Column, Row as RtRow } from "react-table";
import { TerrainModel, TerrainModelFileRevision } from "src/api/generated.api";
import { LayerStatusColors } from "src/features/layer/managed-layer-status-colors";
import TableSimple from "src/components/tables/TableSimple";
import { UploaderProgressBar } from "src/features/uploader/UploaderProgressBar";
import { FileUploadButton } from "src/components/FileUploadButton";
import axios from "axios";
import {
    useChunkUploader,
    DoUploadFunction,
    ChunkInfo,
} from "src/features/uploader/ChunkUploaderService";

import { TerrainModelsTypeRules } from "../terrain-models/terrain-models";

type TMFRrow = RtRow<TerrainModelFileRevision>;

export type EditTerrainModelFilesRevisionProps = { terrainModel: TerrainModel };
export const EditTerrainModelFilesRevisions: FC<
    EditTerrainModelFilesRevisionProps
> = ({ terrainModel }) => {
    const [createRevision] = useCreateFileRevisionForTerrainModelMutation();
    const { data, isFetching } = useFindRevsForTerrainModelQuery({
        terrainModelId: terrainModel.id,
    });
    const [deleteRevision] = useDeleteFileRevisionForTerrainModelMutation();
    const [setCurrent] = useSetCurrentFileRevisionForTerrainModelMutation();

    const columns: Column<TerrainModelFileRevision>[] = [
        {
            Header: "Current",
            width: "auto",
            Cell: ({ row: { original } }: { row: TMFRrow }) => (
                <ToggleButton
                    id={`radio-${original.id}`}
                    type="radio"
                    name="radio"
                    value={original.id}
                    checked={original.isCurrent}
                    className="btnRadio"
                    onChange={(e) =>
                        e.currentTarget.checked
                            ? setCurrent({
                                revisionId: original.id,
                                terrainModelId: terrainModel.id,
                            })
                            : null
                    }
                ></ToggleButton>
            ),
        },
        {
            Header: "Revision Id",
            width: "auto",
            Cell: ({ row: { original } }: { row: TMFRrow }) => (
                <div>{original.id}</div>
            ),
        },
        {
            Header: "Original Filename",
            width: "auto",
            Cell: ({ row: { original } }: { row: TMFRrow }) => (
                <div>{original.originalFilename}</div>
            ),
        },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row: { original } }: { row: TMFRrow }) => (
                <div>{original.createdAt}</div>
            ),
        },
        {
            Header: "Progress",
            Cell: ({ row: { original } }: { row: TMFRrow }) => (
                <ProgressWidget
                    revision={original}
                    terrainModel={terrainModel}
                />
            ),
        },
        {
            Header: " ",
            width: "auto",
            Cell: ({ row: { original } }: { row: TMFRrow }) => (
                <>
                    {original.progress === "uploading" && (
                        <UploadFileButton
                            revision={original}
                            terrainModel={terrainModel}
                        ></UploadFileButton>
                    )}
                </>
            ),
        },
        {
            Header: "Status",
            Cell: ({ row: { original } }: { row: TMFRrow }) => (
                <Badge
                    variant={
                        LayerStatusColors[
                        original.status ? original.status : "dirty"
                        ]
                    }
                >
                    {original.status === "dirty" ? "Dirty" : "Up to date"}
                </Badge>
            ),
        },
        {
            Header: "Action",
            Cell: ({ row: { original } }: { row: TMFRrow }) => (
                <>
                    <Button
                        variant="danger"
                        onClick={() =>
                            deleteRevision({
                                revisionId: original.id,
                                terrainModelId: terrainModel.id,
                            })
                        }
                    >
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div className="mb-2">
            <Row className="mb-2">
                <Col>
                    <h3>Attached datasets</h3>
                </Col>
                <Col md="auto" className="align-items-end d-flex">
                    <Button
                        onClick={() =>
                            createRevision({ terrainModelId: terrainModel.id })
                        }
                    >
                        Create new version
                    </Button>
                </Col>
            </Row>
            <TableSimple
                columns={columns}
                data={data || []}
                fetchData={() => { }}
                loading={isFetching}
            />
        </div>
    );
};

type UploadFileButtonProps = {
    revision: TerrainModelFileRevision;
    terrainModel: TerrainModel;
};
const UploadFileButton: FC<UploadFileButtonProps> = ({
    revision,
    terrainModel,
}) => {
    const { id: terrainModelId, meshDataSourceType } = terrainModel;
    const { addToQueue, uploadQueue } = useChunkUploader();
    const [completeUpload] = useCompleteTerrainModelFileUpload();
    const [errorMsg, setError] = useState<string>('');

    const uploadFuncFactory = (): DoUploadFunction => {
        return async (chunkInfo: ChunkInfo, abortSignal: AbortSignal) => {
            const formData = new FormData();
            const queryString = new URLSearchParams({
                partNumber: `${chunkInfo.partNumber}`,
            });
            formData.append("file", chunkInfo.chunk, chunkInfo.fileName);
            await axios.get("/api/auth/refreshToken");
            const res = await axios.put(
                `/api/terrain-model/${terrainModelId}/tiles-revision/${revision.id
                }/upload-part?${queryString.toString()}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    signal: abortSignal,
                },
            );

            if (chunkInfo.partNumber === chunkInfo.totalParts) {
                await completeUpload({
                    revisionId: revision.id,
                    terrainModelId,
                });
            }
        };
    };

    const onFileSelected = async (file: File) => {
        const ext = file.name.toLowerCase().split('.').pop();
        if (!ext) {
            return;
        }
        const rules = TerrainModelsTypeRules.find(({ ext: exts }) => exts.includes(ext));

        if (rules?.validationFunction(file)) {
            const uploadFunc = uploadFuncFactory();
            const tasks = await addToQueue([file], uploadFunc);
            tasks.forEach((task) => {
                task.metadata = { revisionId: revision.id };
            });
        }
    };

    const isInQueue = useMemo(() => {
        return uploadQueue.find(
            ({ metadata }) => metadata?.revisionId === revision.id,
        );
    }, [revision, uploadQueue]);


    const maxFileSizeInMb = TerrainModelsTypeRules.find(({ meshDataSourceType: mdst }) => mdst === meshDataSourceType)?.maxFileSizeInMb || 10000;
    const acceptExt = '.' + TerrainModelsTypeRules.find(({ meshDataSourceType: mdst }) => mdst === meshDataSourceType)?.ext.join(',.') || ".zip,.glb,.laz,.las,.3tz";

    return (
        <>
            {isInQueue ? (
                <div>Cancel</div>
            ) : (
                <FileUploadButton
                    tooltipText={'Upload ' + acceptExt + ' file'}
                    onFileSelected={onFileSelected}
                    accept={acceptExt}
                    maxFileSizeInMb={maxFileSizeInMb}
                >
                    Add files
                </FileUploadButton>
            )}
        </>
    );
};

const ProgressWidget: FC<UploadFileButtonProps> = ({ revision }) => {
    const { uploadQueue } = useChunkUploader();

    const task = useMemo(
        () =>
            uploadQueue.find(
                ({ metadata }) => metadata?.revisionId === revision.id,
            ),
        [revision, uploadQueue],
    );

    return (
        <>
            {revision.progress === "uploading" && task ? (
                <UploaderProgressBar uploadId={task.uploadId} />
            ) : (
                <div>{revision.progress}</div>
            )}
        </>
    );
};
