/* eslint import/no-webpack-loader-syntax: off */
import { FC, useEffect, useState, useContext } from "react";
import {
    FieldHookConfig,
    Formik,
    Form as FormikForm,
    useField,
    useFormikContext,
} from "formik";
import {
    BootstrapTextInput,
    FkStackedDropdownKeys,
} from "../../components/BootstrapFormComponents";
import * as Yup from "yup";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Collapse,
    FormLabel,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { TerrainSelector } from "../terrains/TerrainSelector";
import {
    DownloadedFile,
    GeoFeature,
    Slide,
    Terrain,
    TerrainSummaryDto,
    Vector3,
} from "src/api/generated.api";
import {
    useDeleteSlideMutation,
    useGetSlideQuery,
    useUpdateSlideMutation,
    useUnlinkTerrainFromSlideMutation,
    useSlidesFilesControllerUploadGeoJsonToSlideMutation,
    useSlidesControllerDeleteGeofeatureMutation,
    useSlidesFilesControllerGetAllMediaQuery,
    useDeleteFileFromSlideMutation,
    useUploadFileToSlideMutation,
    useSlidesControllerClearGeofeatureMutation,
    useSlideLinkTerrain,
} from "src/api/SlideApi";
import SampleMarkdown from "./SampleMarkdown";
import { Action, Subjects } from "src/api/Permissions";
import { Can } from "src/casl/Can";
import { FkEditQuiz } from "../quiz/FkEditQuiz";
import ErrorBar from "src/components/ErrorBar";
import { LayerMap } from "../terrains/TerrainMap";
import { FkEditVectorObj } from "src/components/FkEditVectorObj";
import { FkEditNumber } from "src/components/FkEditNumber";
import { Link } from "react-router-dom";
import { LangContext } from "src/lang/lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { FileUploadButton } from "src/components/FileUploadButton";
import { useCreateSlideCopyMutation } from "src/api/SlideApi";
import { FkEditMarkdown } from "src/components/FkEditMarkdown";
import TableSimple from "src/components/tables/TableSimple";
import { GeoFeaturesTable } from "./geofeatures-table";
import { DeleteButton } from "src/components/DeleteButton";
import ConfirmDelete from "src/components/ConfirmDelete";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { subject } from "@casl/ability";

const zero = { x: 0, y: 0, z: 0 };
const computeDefaultSize = (slide: Slide) => {
    if (
        !slide ||
        !slide.terrain ||
        !slide.terrain.bounds ||
        (slide.terrain.terrainLayers.length ?? 0) === 0
    ) {
        return {
            mapPosition: { ...zero },
            mapRotation: { ...zero },
            mapScale: 1,
        };
    }
    const terrain = slide.terrain;
    const width = terrain.bounds.pMax.x - terrain.bounds.pMin.x;
    const height = terrain.bounds.pMax.y - terrain.bounds.pMin.y;
    const center = {
        x: terrain.bounds.pMin.x + width / 2,
        y: terrain.bounds.pMin.y + height / 2,
        z: 0,
    };
    const maxSize = Math.max(Math.abs(width), Math.abs(height));
    const scale = maxSize / 2;

    return { mapPosition: center, mapRotation: { ...zero }, mapScale: scale };
};

interface PageEditorProps {
    pageId: number;
    editable: boolean;
    onDeletedPage?: (pageId: number) => void;
}

export default function PageEditor({
    pageId,
    onDeletedPage,
    editable,
}: PageEditorProps) {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const {
        data: slide,
        isError: isErrorLoadingSlide,
        refetch: refetchSlide,
    } = useGetSlideQuery(
        { slideId: pageId },
        { refetchOnMountOrArgChange: true },
    );
    const [updateSlide] = useUpdateSlideMutation();
    const [unlinkTerrainFromSlide] = useUnlinkTerrainFromSlideMutation();
    const [linkTerrain] = useSlideLinkTerrain();

    const [uploadGeojsonToSlide] =
        useSlidesFilesControllerUploadGeoJsonToSlideMutation();
    const [deleteSlide] = useDeleteSlideMutation();
    const {
        data: medias,
        isLoading: isLoadingMedias,
        refetch: refetchMedias,
    } = useSlidesFilesControllerGetAllMediaQuery(
        { slideId: pageId },
        { refetchOnMountOrArgChange: true },
    );
    const [deleteMedia] = useDeleteFileFromSlideMutation();
    const [uploadMedia] = useUploadFileToSlideMutation();
    const [errorMessage, setErrorMessage] = useState("");
    const [showTerrainSelector, setShowTerrainSelector] =
        useState<boolean>(false);

    const [createSlideCopy] = useCreateSlideCopyMutation();

    const quizTypeWithAnswers = [
        "MULTIPLE_CHOICE",
        "SINGLE_CHOICE",
        "REORDER_CHOICE",
    ];

    const [geoImportError, setGeoImportError] = useState("");
    async function onFormikSubmit(values: any, formikBag: any) {
        if (!slide) {
            return;
        }
        formikBag.setSubmitting(true);
        try {
            let savedQuiz = { ...values.quiz };
            if (!quizTypeWithAnswers.includes(values.quizType)) {
                savedQuiz.possibleAnswers = [];
            }
            await updateSlide({
                slideId: slide?.id,
                updateSlideDto: {
                    title: values.title,
                    content: values.content,
                    quiz: savedQuiz,
                    quizType: values.quizType,
                    mapPosition: values.mapPosition,
                    mapRotation: values.mapRotation,
                    mapScale: values.mapScale,
                    startupPositionMode: values.startupPositionMode,
                },
            });
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    }

    async function onDeleteClick() {



        await deleteSlide({ slideId: pageId })
            .unwrap()
            .then((res) => {
                if (onDeletedPage) {
                    onDeletedPage(pageId);
                }
                return res;
            })
            .catch((err) => {
                setErrorMessage(err?.data?.message ? err.data.message : JSON.stringify(err));
            });
    }

    async function saveTerrain({ id }: TerrainSummaryDto) {
        if (slide) {
            await linkTerrain({ slideId: slide.id, terrainId: id });
        }
    }

    async function removeTerrain() {
        if (slide) {
            await unlinkTerrainFromSlide({ slideId: pageId });
        }
    }

    const validationSchema = Yup.object({
        title: Yup.string().required(),
        content: Yup.string(),
    });

    const onFileSelected = async (file: File) => {
        if (slide) {
            const formData = new FormData();
            formData.append("file", file);
            try {
                await axios.post(
                    `/api/slides/${slide.id}/upload-geojson`,
                    formData,
                );
                setGeoImportError("");
            } catch (error) {
                setGeoImportError("Failed to import json");
            }
            refetchSlide();
        }
    };

    const onMediaSelected = async (file: File) => {
        if (!slide) return;
        const formData = new FormData();
        formData.append("file", file);
        try {
            const resp = await axios.post(
                `/api/slides/${slide.id}/upload-file`,
                formData,
            );
        } catch (error) {
            console.log(error);
        }
        refetchMedias();
    };

    const onDeleteFile = async (fileId: string) => {
        if (slide) {
            await deleteMedia({ fileId, slideId: slide.id });
        }
    };

    const onCreateCopyClicked = async () => {
        if (slide) {
            await createSlideCopy({ slideId: slide.id });
        }
    };

    return (
        <div>
            {slide && !isErrorLoadingSlide && (
                <div>
                    <ErrorBar errorMessage={errorMessage} />
                    <Formik
                        initialValues={{
                            ...slide,
                            mapPosition: slide.mapPosition ?? { ...zero },
                            mapScale: slide.mapScale ?? 1,
                            mapRotation: slide.mapRotation ?? { ...zero },
                        }}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={onFormikSubmit}
                    >
                        {(formik) => (
                            <FormikForm
                                onSubmit={formik.handleSubmit}
                                onChange={formik.handleChange}
                                method="post"
                            >
                                <Container fluid className="mt-3 mb-3">
                                    <Row className="mb-3">
                                        <Col>
                                            <h1>{Sentences.slideEdit.en}</h1>
                                        </Col>
                                        {editable && (
                                            <>
                                                <Col
                                                    md="auto"
                                                    className="align-items-end d-flex"
                                                >
                                                    <Button
                                                        type="submit"
                                                        disabled={!formik.dirty}
                                                    >
                                                        {Sentences.save.en}
                                                    </Button>
                                                </Col>
                                                <Col
                                                    md="auto"
                                                    className="align-items-end d-flex"
                                                >
                                                    <Button
                                                        onClick={
                                                            onCreateCopyClicked
                                                        }
                                                        disabled={formik.dirty}
                                                    >
                                                        {
                                                            Sentences.createCopy
                                                                .en
                                                        }
                                                    </Button>

                                                </Col>
                                                {editable && (<Col
                                                    md="auto"
                                                    className="align-items-end d-flex"
                                                >

                                                    <DeleteButtonWithConfirm
                                                        onClick={onDeleteClick}
                                                        message="This will delete this slide. Are you sure ?"
                                                    >
                                                        {Sentences.slideDelete.en}
                                                    </DeleteButtonWithConfirm>

                                                </Col>)}
                                            </>
                                        )}
                                    </Row>
                                    <BootstrapTextInput
                                        label={Sentences.title.en}
                                        name="title"
                                        placeholder={Sentences.slideTitle.en}
                                        disabled={!editable}
                                    />
                                    <Card className="mb-3">
                                        <Card.Header>
                                            {Sentences.slideContent.en}
                                        </Card.Header>
                                        <Card.Body>
                                            <FkEditMarkdown
                                                name="content"
                                                disabled={!editable}
                                            />
                                            {editable && (
                                                <>
                                                    <div>
                                                        Don't know how to write
                                                        markdown ?{" "}
                                                        <Button
                                                            onClick={() => {
                                                                formik.setFieldValue(
                                                                    "content",
                                                                    SampleMarkdown,
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                Sentences
                                                                    .generateSampleContent
                                                                    .en
                                                            }
                                                        </Button>
                                                        {
                                                            " or try an external editor like "
                                                        }
                                                        <a
                                                            target="_blank"
                                                            href="https://stackedit.io/app#"
                                                        >
                                                            StackEdit
                                                        </a>
                                                        .
                                                    </div>
                                                    <FkInsertMediaMarkdownButton
                                                        name="content"
                                                        slideId={slide.id}
                                                    />
                                                </>
                                            )}
                                        </Card.Body>
                                    </Card>

                                    <Card className="section">
                                        <Card.Header>
                                            {Sentences.interactiveContent.en}
                                        </Card.Header>
                                        <Card.Body>
                                            <FkEditQuiz
                                                name="quiz"
                                                type="quizType"
                                                disabled={!editable}
                                            />
                                        </Card.Body>
                                    </Card>
                                    <Row className="section">
                                        <Col sm={2}>
                                            <FormLabel>
                                                {ObjectNames.terrain.en}:
                                            </FormLabel>
                                        </Col>
                                        <Col>
                                            <div>
                                                {slide.terrain ? (
                                                    <Link
                                                        to={`/terrains/${slide.terrain.id}`}
                                                    >
                                                        {slide.terrain.name}
                                                    </Link>
                                                ) : (
                                                    Sentences.terrainNoSelected
                                                        .en
                                                )}
                                            </div>
                                        </Col>
                                        {editable && (
                                            <Col sm="auto">
                                                {!slide.terrain ? (
                                                    <Button
                                                        onClick={() => {
                                                            setShowTerrainSelector(
                                                                (value) =>
                                                                    !value,
                                                            );
                                                        }}
                                                        aria-controls="example-collapse-text"
                                                        aria-expanded={
                                                            showTerrainSelector
                                                        }
                                                    >
                                                        {showTerrainSelector
                                                            ? Sentences.cancel
                                                                .en
                                                            : Sentences
                                                                .terrainChoose
                                                                .en}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={removeTerrain}
                                                    >
                                                        {
                                                            Sentences
                                                                .terrainRemove
                                                                .en
                                                        }
                                                    </Button>
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                    {editable && (
                                        <Collapse in={showTerrainSelector}>
                                            <div>
                                                {showTerrainSelector && (
                                                    <>
                                                        <Row>
                                                            <h3>
                                                                {
                                                                    Sentences
                                                                        .terrainSelect
                                                                        .en
                                                                }
                                                            </h3>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <TerrainSelector
                                                                    onTerrainSelected={(
                                                                        terrain,
                                                                    ) => {
                                                                        saveTerrain(
                                                                            {
                                                                                ...terrain,
                                                                            },
                                                                        );
                                                                        setShowTerrainSelector(
                                                                            false,
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </div>
                                        </Collapse>
                                    )}
                                    <Card className="section">
                                        <Card.Header>
                                            Medias (Compatible types can be
                                            embeded in markdown)
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    number of media:{" "}
                                                    {medias !== undefined
                                                        ? medias.length
                                                        : "?"}
                                                </Col>
                                                {editable && (
                                                    <Col md="auto">
                                                        <FileUploadButton
                                                            tooltipText="Upload media file"
                                                            onFileSelected={
                                                                onMediaSelected
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                            {medias && medias.length > 0 && (
                                                <TableSimple
                                                    columns={[
                                                        {
                                                            Header: "Filename",
                                                            width: undefined,
                                                            Cell: ({
                                                                row,
                                                            }: {
                                                                row: {
                                                                    original: DownloadedFile;
                                                                };
                                                            }) => (
                                                                <div>
                                                                    {
                                                                        row
                                                                            .original
                                                                            .name
                                                                    }
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            Header: "Type",
                                                            width: 125,
                                                            Cell: ({
                                                                row,
                                                            }: {
                                                                row: {
                                                                    original: DownloadedFile;
                                                                };
                                                            }) =>
                                                                row.original
                                                                    .mimeType,
                                                        },
                                                        {
                                                            Header: "Action",
                                                            width: 125,
                                                            Cell: ({
                                                                row,
                                                            }: {
                                                                row: {
                                                                    original: DownloadedFile;
                                                                };
                                                            }) => (
                                                                <>
                                                                    {editable && (
                                                                        <>
                                                                            {[
                                                                                "image/png",
                                                                                "image/jpeg",
                                                                            ].includes(
                                                                                row
                                                                                    .original
                                                                                    .mimeType,
                                                                            ) && (
                                                                                    <FkAttachMediaMarkdownButton
                                                                                        name="content"
                                                                                        slideId={
                                                                                            slide.id
                                                                                        }
                                                                                        mediaFileId={
                                                                                            row
                                                                                                .original
                                                                                                .id
                                                                                        }
                                                                                        mediaFileName={
                                                                                            row
                                                                                                .original
                                                                                                .name
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            <Button
                                                                                variant="danger"
                                                                                onClick={() =>
                                                                                    onDeleteFile(
                                                                                        row
                                                                                            .original
                                                                                            .id,
                                                                                    )
                                                                                }
                                                                            >
                                                                                X
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ),
                                                        },
                                                    ]}
                                                    data={medias}
                                                    fetchData={(
                                                        pageIndex: number,
                                                        pageSize: number,
                                                    ) => { }}
                                                    loading={isLoadingMedias}
                                                    initialState={medias || []}
                                                />
                                            )}
                                        </Card.Body>
                                    </Card>
                                    <GeoFeatureCard
                                        geoImportError={geoImportError}
                                        slide={slide}
                                        onFileSelected={onFileSelected}
                                        editable={editable}
                                    />
                                    <Card className="section">
                                        <Card.Header>
                                            {Sentences.slidePosition.en}
                                        </Card.Header>
                                        <Card.Body>
                                            <FkMapPositioner
                                                slide={slide}
                                                disabled={!editable}
                                            ></FkMapPositioner>
                                            <FkStackedDropdownKeys
                                                disabled={!editable}
                                                name="startupPositionMode"
                                                label={`${Sentences.atSlideStartup.en}:`}
                                                items={[
                                                    {
                                                        id: "DEFAULT",
                                                        name: Sentences
                                                            .slideUseDefaultTerrainPosition
                                                            .en,
                                                    },
                                                    {
                                                        id: "CUSTOM",
                                                        name: Sentences
                                                            .slideUseCustomTerrainPosition
                                                            .en,
                                                    },
                                                    {
                                                        id: "LATEST_OR_DEFAULT",
                                                        name: Sentences
                                                            .slideUseLatestOrDefault
                                                            .en,
                                                    },
                                                    {
                                                        id: "LATEST_OR_CUSTOM",
                                                        name: Sentences
                                                            .slideUseLatestOrCustom
                                                            .en,
                                                    },
                                                ]}
                                            />
                                            {[
                                                "CUSTOM",
                                                "LATEST_OR_CUSTOM",
                                            ].includes(
                                                formik.values
                                                    .startupPositionMode,
                                            ) && (
                                                    <Row>
                                                        <Col>
                                                            <FkEditVectorObj
                                                                disabled={!editable}
                                                                name="mapPosition"
                                                                label={
                                                                    Sentences
                                                                        .slideCustomPositionLabel
                                                                        .en
                                                                }
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <FkEditNumber
                                                                disabled={!editable}
                                                                name="mapRotation.y"
                                                                label={
                                                                    Sentences
                                                                        .slideViewHeadingLabel
                                                                        .en
                                                                }
                                                            />
                                                            <FkEditNumber
                                                                disabled={!editable}
                                                                name="mapScale"
                                                                label={
                                                                    Sentences
                                                                        .slideMapScaleLabel
                                                                        .en
                                                                }
                                                            />
                                                            {editable && (
                                                                <Button
                                                                    onClick={() => {
                                                                        const pose =
                                                                            computeDefaultSize(
                                                                                slide,
                                                                            );
                                                                        if (pose) {
                                                                            formik.setFieldValue(
                                                                                "mapPosition",
                                                                                pose.mapPosition,
                                                                            );
                                                                            formik.setFieldValue(
                                                                                "mapRotation",
                                                                                pose.mapRotation,
                                                                            );
                                                                            formik.setFieldValue(
                                                                                "mapScale",
                                                                                pose.mapScale,
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    Reset
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                )}
                                        </Card.Body>
                                    </Card>

                                    {/*
                                <Card className='mb-3'>
                                    <Card.Header>
                                        Objects to display
                                    </Card.Header>
                                    <Card.Body>
                                        
                                    </Card.Body>
                                </Card>
                                */}
                                </Container>
                            </FormikForm>
                        )}
                    </Formik>

                </div>
            )}
            {isErrorLoadingSlide && (
                <div>
                    {Sentences.slideErrorLoading.en} {pageId}
                </div>
            )}
        </div>
    );
}

type FkInsertMediaMarkdownButtonProps = FieldHookConfig<any> & {
    slideId: number;
};

const FkInsertMediaMarkdownButton: FC<FkInsertMediaMarkdownButtonProps> = (
    props,
) => {
    const { setFieldValue } = useFormikContext();

    const [field, meta] = useField(props);
    const { slideId } = props;
    const { refetch: refetchMedias } = useSlidesFilesControllerGetAllMediaQuery(
        { slideId },
    );
    const onMediaSelected = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const resp = await axios.post(
                `/api/slides/${slideId}/upload-file`,
                formData,
            );
            setFieldValue(
                props.name,
                field.value +
                `![${resp.data.name}](${window.location.protocol}//${window.location.host}/api/slides/${slideId}/files/${resp.data.id}/content)\n`,
            );
        } catch (error) {
            console.log(error);
        }
        refetchMedias();
    };
    return (
        <FileUploadButton
            tooltipText="Insert media"
            onFileSelected={onMediaSelected}
        />
    );
};

type FkAttachMediaMarkdownButtonProps = FieldHookConfig<any> & {
    slideId: number;
    mediaFileId: string;
    mediaFileName: string;
};

const FkAttachMediaMarkdownButton: FC<FkAttachMediaMarkdownButtonProps> = (
    props,
) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);
    const { slideId, mediaFileId, mediaFileName } = props;
    const onMediaSelected = () => {
        setFieldValue(
            props.name,
            field.value +
            `![${mediaFileName}](${window.location.protocol}//${window.location.host}/api/slides/${slideId}/files/${mediaFileId}/content)\n`,
        );
    };
    return (
        <Button onClick={onMediaSelected}>
            <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => (
                    <Tooltip id={props.id} {...props}>
                        Insert media in markdown
                    </Tooltip>
                )}
                placement="bottom"
            >
                <FontAwesomeIcon icon={faLink} />
            </OverlayTrigger>
        </Button>
    );
};

export type FkMapPositionerProps = {
    /** optional label for the field */
    slide: Slide;
    disabled?: boolean;
};

export const FkMapPositioner: FC<FkMapPositionerProps> = ({
    slide,
    disabled,
    ...props
}) => {
    const { Sentences } = useContext(LangContext);

    const { values, setFieldValue } = useFormikContext<Slide>();
    const [mapPose, setMapPose] = useState<
        | { mapPosition: Vector3; mapRotation: Vector3; mapScale: number }
        | undefined
    >();

    const onMapClicked = (coords: any) => {
        if (!disabled)
            setFieldValue("mapPosition", { x: coords[0], y: coords[1], z: 0 });
    };

    useEffect(() => {
        if (
            values.startupPositionMode === "DEFAULT" ||
            values.startupPositionMode === "LATEST_OR_DEFAULT"
        ) {
            setMapPose(computeDefaultSize(slide));
        } else {
            setMapPose({
                mapPosition: values.mapPosition ?? { ...zero },
                mapRotation: values.mapRotation ?? { ...zero },
                mapScale: values.mapScale ?? 1,
            });
        }
    }, [
        slide,
        values.mapPosition,
        values.mapRotation,
        values.mapScale,
        values.startupPositionMode,
    ]);

    return (
        <>
            {slide.terrain && slide.terrain.terrainLayers?.length > 0 && (
                <>
                    <div>
                        <LayerMap
                            terrainLayer={slide.terrain.terrainLayers[0]}
                            mapConfObjs={mapPose}
                            onMapClicked={onMapClicked}
                            mapHeight="600px"
                            geojson={{
                                type: "FeatureCollection",
                                features: slide.geoFeatures.map(({ content }) =>
                                    JSON.parse(content),
                                ),
                            }}
                            {...props}
                        />
                    </div>
                    <div>{Sentences.slidePositionMapLegend.en}</div>
                </>
            )}
        </>
    );
};
const GeoFeatureCard = ({
    geoImportError,
    slide,
    onFileSelected,
    editable,
}: {
    geoImportError: string;
    slide: Slide;
    onFileSelected: (file: File) => Promise<void>;
    editable: boolean;
}) => {
    const [deleteGeoJson] = useSlidesControllerDeleteGeofeatureMutation();
    const [clearGeoJson] = useSlidesControllerClearGeofeatureMutation();
    const onDeleteGeoJsonClicked = async (geoFeatureId: string) => {
        if (slide) {
            await deleteGeoJson({ slideId: slide.id, geoFeatureId });
        }
    };
    return (
        <Card className="section">
            <Card.Header>
                Geo objects (Seen as read only during session)
            </Card.Header>
            <Card.Body>
                <ErrorBar errorMessage={geoImportError} />
                <Row>
                    <Col>number of objects: {slide.geoFeatures.length}</Col>
                    {editable && (
                        <>
                            <Col md="auto">
                                <FileUploadButton
                                    tooltipText="Upload geojson file"
                                    onFileSelected={onFileSelected}
                                />
                            </Col>
                            <Col md="auto">
                                <DeleteButtonWithConfirm
                                    onClick={() => {
                                        clearGeoJson({ slideId: slide.id });
                                    }}
                                    message="About to clear all geofeatures. Are you sure ?"
                                >
                                    Clear All
                                </DeleteButtonWithConfirm>
                            </Col>
                        </>
                    )}
                </Row>
                {slide.geoFeatures.length > 0 && (
                    <GeoFeaturesTable
                        geofeatures={slide.geoFeatures}
                        onDeleteGeoJsonClicked={
                            editable ? onDeleteGeoJsonClicked : undefined
                        }
                    />
                )}
            </Card.Body>
        </Card>
    );
};
