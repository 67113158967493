import { Subjects } from "./Permissions";
import {
    DocumentAclTagList,
    invalidateDocumentAclEntryTag,
    invalidateDocumentTagsFromId,
    provideDocumentAclTags,
} from "./document-access-control.api";
import { api as generatedApi } from "./generated.api";
import { io, Socket } from "socket.io-client";

export const TerrainModelApi = generatedApi.enhanceEndpoints({
    addTagTypes: [
        "TerrainModel",
        "TerrainModelFilesRevision",
        "TerrainModelsCapabilities",
        ...DocumentAclTagList,
    ],
    endpoints: {
        terrainModelsControllerGetAll: {
            providesTags: (result) =>
                result
                    ? [
                        ...result.results
                            .map((model) => [
                                {
                                    type: "TerrainModel" as const,
                                    id: model.id,
                                },
                                ...provideDocumentAclTags(
                                    model,
                                    Subjects.TerrainModel,
                                ),
                            ])
                            .flat(),
                        { type: "TerrainModel" as const, id: "All" },
                    ]
                    : [{ type: "TerrainModel" as const, id: "All" }],
        },
        terrainModelsControllerGetAllWithRelations: {
            providesTags: (result) =>
                result
                    ? [
                        ...result.results
                            .map((model) => [
                                {
                                    type: "TerrainModel" as const,
                                    id: model.id,
                                },
                                ...provideDocumentAclTags(
                                    model,
                                    Subjects.TerrainModel,
                                ),
                            ])
                            .flat(),
                        { type: "TerrainModel" as const, id: "All" },
                    ]
                    : [{ type: "TerrainModel" as const, id: "All" }],
        },
        terrainModelsControllerGetOne: {
            providesTags: (result) =>
                result
                    ? [
                        { type: "TerrainModel" as const, id: result.id },
                        ...provideDocumentAclTags(
                            result,
                            Subjects.TerrainModel,
                        ),
                    ]
                    : [],
        },
        terrainModelsControllerCreate: (endpoint) => {
            endpoint.invalidatesTags = (result) => [
                { type: "TerrainModel" as const, id: "All" },
            ];
        },
        terrainModelsControllerRemove: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                { type: "TerrainModel" as const, id: arg.terrainModelId },
                { type: "TerrainModel" as const, id: "All" },
                ...invalidateDocumentTagsFromId(
                    Subjects.TerrainModel,
                    arg.terrainModelId,
                ),
            ];
        },
        terrainModelsControllerForceRemove: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                { type: "TerrainModel" as const, id: arg.terrainModelId },
                { type: "TerrainModel" as const, id: "All" },
                ...invalidateDocumentTagsFromId(
                    Subjects.TerrainModel,
                    arg.terrainModelId,
                ),
            ];
        },
        terrainModelsControllerUpdate: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                { type: "TerrainModel" as const, id: arg.terrainModelId },
                { type: "TerrainModel" as const, id: "All" },
            ];
        },
        terrainModelFileControllerFindRevs: {
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({
                            type: "TerrainModelFilesRevision" as const,
                            id,
                        })),
                        "TerrainModelFilesRevision",
                    ]
                    : [],
            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/TerrainModelFilesRevision");
                await api.cacheDataLoaded;
                const c = api.getCacheEntry();
                const ids = c.data?.map(({ id }) => id) || [];
                socket.on("update", (_) => {
                    api.dispatch(
                        TerrainModelApi.util.invalidateTags([
                            ...ids.map((id) => ({
                                type: "TerrainModelFilesRevision" as const,
                                id,
                            })),
                        ]),
                    );
                });
                socket.emit("register", ids);
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
        terrainModelFileControllerFindByRevId: {
            providesTags: (result) =>
                result
                    ? [
                        {
                            type: "TerrainModelFilesRevision" as const,
                            id: result.id,
                        },
                    ]
                    : [],
            onCacheEntryAdded: async (arg, api) => {
                const socket: Socket = io("/TerrainModelFilesRevision");
                await api.cacheDataLoaded;
                socket.on("update", (_) => {
                    api.dispatch(
                        TerrainModelApi.util.invalidateTags([
                            {
                                type: "TerrainModelFilesRevision" as const,
                                id: arg.revisionId,
                            },
                        ]),
                    );
                });
                socket.emit("register", [arg.revisionId]);
                await api.cacheEntryRemoved;
                socket.close();
            },
        },
        terrainModelFileControllerCompleteUpload: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                {
                    type: "TerrainModelFilesRevision" as const,
                    id: arg.revisionId,
                },
            ];
        },
        terrainModelFileControllerCreateRevision: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                "TerrainModelFilesRevision",
            ];
        },
        terrainModelFileControllerDeleteRev: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                {
                    type: "TerrainModelFilesRevision" as const,
                    id: arg.revisionId,
                },
                "TerrainModelFilesRevision",
            ];
        },
        terrainModelFileControllerSetCurrent: (endpoint) => {
            endpoint.invalidatesTags = (result, error, arg) => [
                {
                    type: "TerrainModelFilesRevision" as const,
                    id: arg.revisionId,
                },
                "TerrainModelFilesRevision",
                { type: "TerrainModel" as const, id: arg.terrainModelId },
            ];
        },
        terrainModelsCapabilitiesControllerGetCapabilities: {
            providesTags: (result) =>
                result ? ["TerrainModelsCapabilities"] : [],
        },
        terrainModelsControllerAddUser: {
            invalidatesTags: invalidateDocumentAclEntryTag,
        },
        terrainModelsControllerChangeTeam: {
            invalidatesTags: (result, error, arg) => [
                { type: "TerrainModel" as const, id: parseInt(arg.id) },
            ],
        },
        terrainModelsControllerChangeVisibility: {
            invalidatesTags: (result, error, arg) => [
                { type: "TerrainModel" as const, id: parseInt(arg.id) },
            ],
        },
    },
});

export const {
    useTerrainModelsControllerGetAllQuery: useGetAllTerrainModelsQuery,
    useTerrainModelsControllerGetAllWithRelationsQuery: useGetAllWithRelationsTerrainModelsQuery,
    useTerrainModelsControllerCreateMutation: useCreateTerrainModelMutation,
    useTerrainModelsControllerGetOneQuery: useGetOneTerrainModelQuery,
    useTerrainModelsControllerRemoveMutation: useRemoveTerrainModelMutation,
    useTerrainModelsControllerForceRemoveMutation: useForceRemoveTerrainModelMutation,
    useTerrainModelsControllerUpdateMutation: useUpdateTerrainModelMutation,
    useTerrainModelFileControllerCompleteUploadMutation:
    useCompleteTerrainModelFileUpload,
    useTerrainModelFileControllerFindByRevIdQuery:
    useFindRevForTerrainModelQuery,
    useTerrainModelFileControllerFindRevsQuery: useFindRevsForTerrainModelQuery,
    useTerrainModelFileControllerCreateRevisionMutation:
    useCreateFileRevisionForTerrainModelMutation,
    useTerrainModelFileControllerDeleteRevMutation:
    useDeleteFileRevisionForTerrainModelMutation,
    useTerrainModelFileControllerUploadPartMutation:
    useUploadFilePartForTerrainModelMutation,
    useTerrainModelFileControllerSetCurrentMutation:
    useSetCurrentFileRevisionForTerrainModelMutation,
    useTerrainModelsCapabilitiesControllerGetCapabilitiesQuery:
    useGetTerrainModelsCapabilitiesQuery,
    useTerrainModelsControllerAddUserMutation: useTerrainModelsAddUser,
    useTerrainModelsControllerChangeTeamMutation: useChangeTeam,
    useTerrainModelsControllerChangeVisibilityMutation: useChangeVisibility,
} = TerrainModelApi;
