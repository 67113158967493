import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { set } from 'ol/transform';
import { FC, useState } from 'react';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import { FallingLines } from 'react-loader-spinner';

export type FileUploadPopinProps = {
    title: string;
    callback: (acceptedFiles: File[]) => void;
    maxFiles?: number; // Optional prop to limit the number of files
    acceptedExtensions?: string; // Optional prop to limit the file types
}

export const FileUploadPopin: FC<FileUploadPopinProps> = ({ title, callback, maxFiles = 10, acceptedExtensions }) => {
    const [showPopin, setShowPopin] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Custom function to handle file drops (calls the callback prop)
    const handleFileDrop = async (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        setIsLoading(true);
        if (acceptedFiles.length > 0) {
            // Call the passed-in callback function with the dropped files
            await callback(acceptedFiles);

            setShowPopin(false);
            setIsLoading(false);

        }
        else {
            if (fileRejections.length > 0) {
                fileRejections.forEach((fileRejection) => {
                    if (fileRejection.errors.length > 0) {
                        const e = Array.from(new Set([...errors, fileRejection.errors[0].message]))
                        setErrors(e);
                    }
                });
            }
            setIsLoading(false);
        };
    }

    // Set up Dropzone 
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleFileDrop,
        maxFiles
    });

    // Inline styles
    const styles = {
        overlay: {
            position: 'fixed' as 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        },
        content: {
            background: '#4276EA',
            padding: '30px',
            borderRadius: '10px',
            zIndex: 1000,
            width: '700px',
            textAlign: 'center' as 'center',
            position: 'relative' as 'relative',
        },
        closeButton: {
            position: 'absolute' as 'absolute',
            top: '0px',
            right: '5px',
            background: 'none',
            border: 'none',
            fontSize: '28px',
            cursor: 'pointer',
            color: '#fff',
        },
        dropzone: {
            display: 'flex',
            flexDirection: 'column' as 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '80px',
            border: '4px dashed rgba(255,255,255,0.5)',
            borderRadius: '10px',
            backgroundColor: '#5C8AEF',
            fontSize: '1.2rem',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        dropzoneHover: {
            backgroundColor: '#5C8AEF',
        },
        previewContainer: {
            marginTop: '0px',
        },
        filePreview: {
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'center',
            marginTop: '10px',
        },
        filePreviewImg: {
            width: '100px',
            height: '100px',
            objectFit: 'cover' as 'cover',
            marginBottom: '10px',
        },
        filePreviewText: {
            fontSize: '14px',
            wordBreak: 'break-word' as 'break-word',
        }
    };

    return (
        <>
            <button className="btn btn-primary" onClick={() => setShowPopin(true)}>
                <FontAwesomeIcon
                    className="mr-2"
                    icon={faPlus}
                />{" "}
                {title ?? 'Upload File' + (maxFiles > 1 ? 's' : '')}
            </button>

            {showPopin && (
                <div style={styles.overlay}>
                    <div style={styles.content}>
                        <button style={styles.closeButton} onClick={() => setShowPopin(false)}>×</button>
                        <div {...getRootProps({ style: isDragActive ? { ...styles.dropzone, ...styles.dropzoneHover } : styles.dropzone })}>
                            <input {...getInputProps()} accept={acceptedExtensions || '*'} />
                            {isLoading ? <FallingLines
                                color="#fff"
                                width="100"
                                visible={true}
                            /> : <>
                                {isDragActive ? <p>Drop the file{maxFiles > 1 ? 's' : ''} here...</p> : <p>Drag & drop some file{maxFiles > 1 ? 's' : ''} here, or click to select file{maxFiles > 1 ? 's' : ''}...</p>}
                                <p style={{ fontSize: '1rem' }}>{acceptedExtensions ?? ''}</p></>}
                        </div>
                        {errors.length ? <div className='mt-4'>
                            {errors.map((error, index) => (
                                <p className='m-0 p-0' key={index}>{error} !</p>
                            ))}
                        </div> : <></>}
                    </div>
                </div>
            )}
        </>
    );
};

