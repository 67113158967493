import React, { FC, useEffect, useContext, useState, useMemo } from "react";

import { Action, Subjects } from "src/api/Permissions";
import { TerrainLayer, TerrainModel, useLayerControllerFindAllWithRelationsQuery, useLayerControllerRemoveMutation, useTerrainModelsControllerForceRemoveMutation, useTerrainModelsControllerGetAllWithRelationsQuery } from "../../api/generated.api";

import { Col, Container, Row } from "react-bootstrap";

import { LangContext } from "src/lang/lang";


import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { Column, Row as TableRow } from "react-table";
import { subject } from "@casl/ability";
import { ViewOrEditLinkButton } from "src/components/view-or-edit-link-button";

import TableWithPagination from "src/components/tables/TableWithPagination";
import moment from "moment";


import { teamContext } from "../teams/context/team-context-provider";

import { Can } from "src/casl/Can";
import { useMeshSourceTypes } from "../terrains/terrain-models/terrain-model-types";
import { useForceDeleteLayerMutation, useGetAllLayersWithRelationsQuery } from "src/api/LayerApi";
import { useForceRemoveTerrainModelMutation, useGetAllWithRelationsTerrainModelsQuery } from "src/api/TerrainModelApi";


export const StorageUsageAnalyzer: FC<any> = () => {
    const { ObjectNames } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);
    const terrainModelTypeMap = useMeshSourceTypes();

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(5);

    const [layersfilter, setlayersFilter] = React.useState("");
    const [layerssort, setlayersSort] = React.useState("");
    const [layerspageIndex, setlayersPageIndex] = React.useState(0);
    const [layerspageSize, setlayersPageSize] = React.useState(5);

    const modelsQueryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : "",
    }

    const layersQueryProps = {
        offset: layerspageIndex * layerspageSize,
        limit: layerspageSize,
        filter: layersfilter,
        sort: layerssort,
        teamId: currentTeam ? currentTeam.id : "",
    }

    const {
        data: terrainModels,
        isLoading,
        isFetching,
        isError: getIsError,
        error: getError,
    } = useGetAllWithRelationsTerrainModelsQuery(modelsQueryProps,
        { refetchOnMountOrArgChange: true });

    const {
        data: terrainLayers,
        isFetching: isFetchingLayers
    } = useGetAllLayersWithRelationsQuery(layersQueryProps,
        { refetchOnMountOrArgChange: true });

    const [deleteTerrainModel, { isError: deleteIsError, error: deleteError }] =
        useForceRemoveTerrainModelMutation();

    const [deleteLayer, { isError: deleteLayerIsError, error: deleteLayerError }] =
        useForceDeleteLayerMutation();

    const performDelete = (terrainModelId: TerrainModel["id"]) => {
        deleteTerrainModel({ terrainModelId });
    };


    const performLayerDelete = (layerId: TerrainLayer["id"]) => {
        deleteLayer({ layerId });
    };

    const columns: Column<TerrainModel>[] = [

        {
            Header: 'Size (Go)',
            width: 120,
            Cell: ({ row }: { row: TableRow<TerrainModel> }) => (<>{row.original.size ? (Number(row.original.size) / Number(1000000000)).toFixed(2).toString() : BigInt(0).toString()}</>),
        },
        {
            Header: 'Used in Scenes ?',
            width: 155,
            Cell: ({ row }: { row: TableRow<TerrainModel> }) => (<>{row.original.terrainToTerrainModels.length}</>),
        },
        {
            Header: 'Name',
            width: undefined,
            accessor: "name",
        },
        {
            Header: "Type",
            width: 300,

            Cell: ({ row }: { row: TableRow<TerrainModel> }) => (
                <small>
                    {
                        terrainModelTypeMap.find(
                            ({ id }) => id === row.original.meshDataSourceType,
                        )?.name
                    }
                </small>
            ),
        },
        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: TerrainModel } }) => {
                const inHowManyScene = row.original.terrainToTerrainModels.length
                const isUsed = inHowManyScene > 0;
                let message = "Are you sure you want to delete ?"
                if (isUsed) {
                    message = "This model is used in " + inHowManyScene + " scene" + (inHowManyScene > 1 ? "s" : "") + ". Are you sure you want to delete it ?"
                }
                return (
                    <div>
                        {" "}

                        <Can
                            I={Action.Delete}
                            this={subject(Subjects.TerrainModel, {
                                ...row.original,
                            })}
                        >
                            <ViewOrEditLinkButton
                                editLink={`/terrain-model/${row.original.id}`}
                                readLink={`/terrain-model/${row.original.id}`}
                                obj={row.original}
                                subjectType={Subjects.TerrainModel}
                            />
                            <DeleteButtonWithConfirm
                                variant="secondary"
                                className="ml-1"
                                message={message}
                                onClick={() => performDelete(row.original.id)}
                            />
                        </Can>
                    </div>
                )
            },
        },
    ];


    const terrainLayersColumns: Column<TerrainLayer>[] = [

        {
            Header: 'Size (Go)',
            width: 120,
            Cell: ({ row }: { row: TableRow<TerrainLayer> }) => (<>{row.original.size ? (Number(row.original.size) / Number(1000000000)).toFixed(2).toString() : BigInt(0).toString()}</>),
        },
        {
            Header: 'Used in Scenes ?',
            width: 155,
            Cell: ({ row }: { row: TableRow<TerrainLayer> }) => (<>{row.original.terrains.length}</>),
        },
        {
            Header: 'Name',
            width: undefined,
            accessor: "name",
        },

        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: TerrainLayer } }) => {
                const inHowManyScene = row.original.terrains.length
                const isUsed = inHowManyScene > 0;
                let message = "Are you sure you want to delete ?"
                if (isUsed) {
                    message = "This layer is used in " + inHowManyScene + " scene" + (inHowManyScene > 1 ? "s" : "") + ". Are you sure you want to delete it ?"
                }
                return (
                    <div>
                        {" "}

                        <Can
                            I={Action.Delete}
                            this={subject(Subjects.TerrainLayer, {
                                ...row.original,
                            })}
                        >
                            <ViewOrEditLinkButton
                                editLink={`/terrain-layers/${row.original.id}`}
                                readLink={`/terrain-layers/${row.original.id}`}
                                obj={row.original}
                                subjectType={Subjects.TerrainLayer}
                            />
                            <DeleteButtonWithConfirm
                                variant="secondary"
                                className="ml-1"
                                message={message}
                                onClick={() => performLayerDelete(row.original.id)}
                            />
                        </Can>
                    </div>
                )
            },
        },
    ];

    return (
        <Container className="section">

            <div className="mb-3">
                <Row>
                    <Col xs={'auto'}>
                        <h1>Storage usage analyzer</h1>
                    </Col>

                </Row>
            </div>
            <TableWithPagination
                title="Models stats"
                columns={columns}
                pageSize={modelsQueryProps.limit}
                data={terrainModels?.results || []}
                fetchData={(
                    pageIndexToFetch: number,
                    pageSizeToFetch: number,
                    filterToFetch: string,
                    sortToFetch: string
                ) => {
                    setPageIndex(pageIndexToFetch);
                    setPageSize(pageSizeToFetch);
                    setFilter(filterToFetch);
                    setSort(sortToFetch);
                }}
                loading={isFetching}
                itemCount={terrainModels?.total || 0}
                enableFiltering={true}
                defaultSort="size:desc"
                sortableProps={[
                    { name: 'Size (Go)', accessor: 'size' },
                ]}
            />

            <TableWithPagination
                title="Layers stats"
                columns={terrainLayersColumns}
                pageSize={modelsQueryProps.limit}
                data={terrainLayers?.results || []}
                fetchData={(
                    pageIndexToFetch: number,
                    pageSizeToFetch: number,
                    filterToFetch: string,
                    sortToFetch: string
                ) => {
                    setlayersPageIndex(pageIndexToFetch);
                    setlayersPageSize(pageSizeToFetch);
                    setlayersFilter(filterToFetch);
                    setlayersSort(sortToFetch);
                }}
                loading={isFetchingLayers}
                itemCount={terrainLayers?.total || 0}
                enableFiltering={true}
                defaultSort="size:desc"
                sortableProps={[
                    { name: 'Size (Go)', accessor: 'size' },
                ]}
            />
        </Container>
    );
};
