
import { Container } from "react-bootstrap";
import { StorageUsageAnalyzer } from "./storage-usage-analyzer";


export default function StorageUsageAnalyzerPage() {
    return (<>
        <Container>
            <StorageUsageAnalyzer />
        </Container>
    </>
    )
}