import TableWithPagination from "src/components/tables/TableWithPagination";
import React, { useContext } from "react";
import Button from "react-bootstrap/Button";

import { Action, Subjects } from "src/api/Permissions";
import { useFindAllTerrainsQuery } from "src/api/TerrainApi";
import { Terrain, TerrainSummaryDto } from "src/api/generated.api";
import { AbilityContext } from "src/casl/Can";
import { LangContext } from "src/lang/lang";
import { teamContext } from "../teams/context/team-context-provider";

interface TerrainSelectorProps {
    onTerrainSelected?: (terrain: TerrainSummaryDto) => void;
}

export function TerrainSelector({ onTerrainSelected }: TerrainSelectorProps) {
    const { ObjectNames, Sentences } = useContext(LangContext);

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");

    const { currentTeam } = useContext(teamContext);

    const { data: terrains, isLoading } = useFindAllTerrainsQuery({
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter,
        sort,
        teamId: currentTeam ? currentTeam.id : "",
        showPublicObjects: true
    });
    const ability = useContext(AbilityContext);

    const columns = [
        {
            Header: ObjectNames.terrain.en,
            width: "100%",
            Cell: ({ row }: { row: { original: TerrainSummaryDto } }) => (
                <div>{row.original.name}</div>
            ),
        },
        {
            Header: "Action",
            Cell: ({ row }: { row: { original: TerrainSummaryDto } }) => (
                <Button
                    onClick={() => {
                        if (onTerrainSelected) onTerrainSelected(row.original);
                    }}
                >
                    {Sentences.select.en}
                </Button>
            ),
        },
    ];

    return (
        <>
            {ability.can(Action.Read, Subjects.Terrains) ? (
                <TableWithPagination
                    fetchData={(
                        pageIndexToFetch: number,
                        pageSizeToFetch: number,
                        filterToFetch: string,
                        sortToFetch: string
                    ) => {
                        setPageIndex(pageIndexToFetch);
                        setPageSize(pageSizeToFetch);
                        setFilter(filterToFetch);
                        setSort(sortToFetch);
                    }}
                    loading={isLoading}
                    columns={columns}
                    data={terrains?.results ?? []}
                    itemCount={terrains?.total ?? 0}
                    enableFiltering={true}
                />
            ) : (
                <div>Sorry you're not allowed to see terrains list</div>
            )}
        </>
    );
}
