import React, { FC, useEffect, useContext, useState, useMemo } from "react";

import { Action, Subjects } from "src/api/Permissions";
import { CreateTerrainModelDto, TerrainModel, TerrainModelFileRevision, TerrainModelWithStatus } from "../../../api/generated.api";
import { Can } from "../../../casl/Can";
import {
    useCompleteTerrainModelFileUpload,
    useCreateFileRevisionForTerrainModelMutation,
    useCreateTerrainModelMutation,
    useGetAllTerrainModelsQuery,
    useGetTerrainModelsCapabilitiesQuery,
    useRemoveTerrainModelMutation,
} from "src/api/TerrainModelApi";

import JSZip from "jszip";

import { Badge, Col, Container, Row } from "react-bootstrap";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { TerrainModelCreator } from "./terrain-model-creator";
import { teamContext } from "../../teams/context/team-context-provider";
import { DeleteButtonWithConfirm } from "src/components/DeleteButtonWithConfirm";
import { Column, Row as TableRow } from "react-table";
import { subject } from "@casl/ability";
import { ViewOrEditLinkButton } from "src/components/view-or-edit-link-button";
import { MeshDataSourceTypes, useMeshSourceTypes } from "./terrain-model-types";
import TableWithPagination from "src/components/tables/TableWithPagination";
import moment from "moment";
import { FileUploadPopin } from "src/components/FileUploadPopin";
import { DropEvent, FileRejection } from "react-dropzone/.";
import { useNavigate } from "react-router-dom";
import { ChunkInfo, DoUploadFunction, useChunkUploader } from "src/features/uploader/ChunkUploaderService";
import axios from "axios";
import { faDotCircle, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "src/features/auth/authSlice";



export const valid3dTiles = async (file: File) => {
    const fileExtension = file.name.toLowerCase().split('.').pop();
    // Check if the file is a ZIP/3tz file
    if (fileExtension != 'zip' && fileExtension != '3tz') {
        alert('Please upload a valid 3dtiles archive file : .zip, .3tz');
        return;
    }

    try {
        const zip = new JSZip();
        const isTilesetJsonFound = await new Promise((resolve, reject) => {
            zip.loadAsync(file, { createFolders: false })
                .then((zipContents) => {
                    let found = false;
                    // Iterate over each file in the zip
                    zipContents.forEach((relativePath, zipEntry) => {
                        // Check only root-level files
                        if (!relativePath.includes('/') && zipEntry.name === 'tileset.json') {
                            found = true;
                            resolve(true); // Resolve as soon as we find the file
                        }
                    });

                    if (!found) {
                        resolve(false); // Resolve false if file not found after the loop
                    }
                })
                .catch((err) => reject(err)); // Catch ZIP loading errors
        });


        // Check if tileset.json is in the root
        if (isTilesetJsonFound) {
            return true;
        } else {
            alert('Error : Your 3dtiles archive file does not contain tileset.json at the root.');
        }
    } catch (err) {
        alert('Error reading 3dtiles archive file. Please try again.');
    }
    return false;
};

export const validGlb = async (file: File) => {
    const fileExtension = file.name.toLowerCase().split('.').pop();
    // Check if the file is a GLB file
    if (fileExtension !== 'glb') {
        alert('Please upload a valid glb file');
        return false;
    }
    return true;
};

export const validPointCloud = async (file: File) => {
    const fileExtension = file.name.toLowerCase().split('.').pop();
    // Check if the file is a LAZ file
    if (fileExtension !== 'laz' && fileExtension !== 'las') {
        alert('Please upload a valid laz file');
        return false;
    }
    return true;
}

export const TerrainModelsTypeRules = [{
    meshDataSourceType: 'TILESV2_FROM_INTERNAL_SOURCE',
    ext: ['zip', '3tz'],
    maxFileSizeInMb: 90000,
    validationFunction: valid3dTiles,
},
{
    meshDataSourceType: 'GLB_FROM_INTERNAL_SOURCE',
    ext: ['glb'],
    maxFileSizeInMb: 100,
    validationFunction: validGlb,
},
{
    meshDataSourceType: 'POINTCLOUD_FROM_INTERNAL_SOURCE',
    ext: ['laz', 'las'],
    maxFileSizeInMb: 150000,
    validationFunction: validPointCloud,
}];


export const TerrainModels: FC<any> = () => {
    const { ObjectNames } = useContext(LangContext);
    const { currentTeam } = useContext(teamContext);
    const [errorMessage, setErrorMessage] = React.useState("");
    const terrainModelTypeMap = useMeshSourceTypes();

    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState("");
    const [showOldTerrainModelCreator, setShowOldTerrainModelCreator] = React.useState(false);
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [showPublicObjects, setShowPublicObjects] = React.useState(false);

    const modelsQueryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : "",
        showPublicObjects
    }

    const {
        data: terrainModels,
        isLoading,
        isFetching,
        isError: getIsError,
        error: getError,
    } = useGetAllTerrainModelsQuery(modelsQueryProps);

    const {
        data: capabilities,
        isError: isErrorFetchingCapabilities,
        error: capError,
    } = useGetTerrainModelsCapabilitiesQuery();

    const [deleteTerrainModel, { isError: deleteIsError, error: deleteError }] =
        useRemoveTerrainModelMutation();

    const performDelete = (terrainModelId: TerrainModel["id"]) => {
        deleteTerrainModel({ terrainModelId });
    };

    const filteredTerrainModelsTypeRules = TerrainModelsTypeRules.filter(({ meshDataSourceType }) => (capabilities || []).includes(meshDataSourceType))

    const getMeshDataSourceType = (fileExtension: string): MeshDataSourceTypes => {
        const terrainModel = filteredTerrainModelsTypeRules.find(({ ext }) => ext.includes(fileExtension));
        return terrainModel?.meshDataSourceType as MeshDataSourceTypes;
    }

    const { addToQueue, uploadQueue } = useChunkUploader();
    const [completeUpload] = useCompleteTerrainModelFileUpload();
    const [errorMsg, setError] = useState<string>('');

    const uploadFuncFactory = (terrainModelId: number, revisionId: string): DoUploadFunction => {
        return async (chunkInfo: ChunkInfo, abortSignal: AbortSignal) => {
            const formData = new FormData();
            const queryString = new URLSearchParams({
                partNumber: `${chunkInfo.partNumber}`,
            });
            formData.append("file", chunkInfo.chunk, chunkInfo.fileName);
            await axios.get("/api/auth/refreshToken");
            const res = await axios.put(
                `/api/terrain-model/${terrainModelId}/tiles-revision/${revisionId
                }/upload-part?${queryString.toString()}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    signal: abortSignal,
                },
            );

            if (chunkInfo.partNumber === chunkInfo.totalParts) {
                await completeUpload({
                    revisionId,
                    terrainModelId,
                });
            }
        };
    };

    const onFileUpload = async (acceptedFiles: File[]) => {
        if (acceptedFiles.length == 1) {
            const file = acceptedFiles[0];
            const fileExtension = file.name.toLowerCase().split('.').pop() as string;
            const meshType = getMeshDataSourceType(fileExtension);
            if (meshType) {
                const rules = filteredTerrainModelsTypeRules.find(({ ext: exts }) => exts.includes(fileExtension));

                if (!await rules?.validationFunction(file)) {
                    return;
                }

                try {
                    const terrainModel = await createTerrainModel({
                        createTerrainModelDto: {
                            name: file.name.replace(/\.[^/.]+$/, ""),
                            meshDataSourceType: meshType as MeshDataSourceTypes,
                            teamId: currentTeam ? currentTeam.id : "",
                        }
                    }).unwrap();

                    const revision = await createRevision({
                        terrainModelId: terrainModel.id,
                    }).unwrap();

                    if (revision.id) {
                        const uploadFunc = uploadFuncFactory(terrainModel.id, revision.id);
                        const tasks = await addToQueue([file], uploadFunc);
                        tasks.forEach((task) => {
                            task.metadata = { revisionId: revision.id };
                        });

                    }

                    navigate(`/terrain-model/${terrainModel.id}`);
                } catch (e) {

                }

            }
        }
    }

    const navigate = useNavigate();

    const [createRevision] = useCreateFileRevisionForTerrainModelMutation();
    const [createTerrainModel, { isError: createIsError, error: createError }] =
        useCreateTerrainModelMutation();


    useEffect(() => {
        const isErr = getIsError || deleteIsError;
        const err = [getError, deleteError].filter((v) => v !== undefined);
        setErrorMessage(isErr ? JSON.stringify(err) : "");
    }, [getIsError, deleteIsError, getError, deleteError]);

    type keyValObj = { [key: string]: string };

    const columns: Column<TerrainModel>[] = [
        {
            Header: 'Name',
            width: undefined,
            accessor: "name",
        },
        {
            Header: "Status",
            width: undefined,
            Cell: ({ row }: { row: { original: TerrainModelWithStatus } }) => {
                const status: string = row.original.progress ? row.original.progress : ''

                const color: keyValObj = {
                    "uploading": "warning",
                    "post_processing": "warning",
                    "done": "success",
                    "error": "danger"
                }

                return (status ? <Badge variant={color[status]}>{status}</Badge > : <></>);
            }
        },
        {
            Header: "Type",
            width: 300,

            Cell: ({ row }: { row: TableRow<TerrainModel> }) => (
                <small>
                    {
                        terrainModelTypeMap.find(
                            ({ id }) => id === row.original.meshDataSourceType,
                        )?.name
                    }
                </small>
            ),
        },
        {
            Header: "Visibility",
            width: 150,
            accessor: "visibility"
        },
        {
            Header: "Created at",
            width: 180,
            Cell: ({ row }: { row: TableRow<TerrainModel> }) => (
                <div>{moment(row.original.createdAt).format('YYYY-MM-DD HH:mm:ss UTC Z')}</div>
            ),
        },
        {
            Header: "Action",
            width: 130,
            Cell: ({ row }: { row: { original: TerrainModel } }) => (
                <div>
                    {" "}
                    <ViewOrEditLinkButton
                        editLink={`/terrain-model/${row.original.id}`}
                        readLink={`/terrain-model/${row.original.id}`}
                        subjectType={Subjects.TerrainModel}
                        obj={row.original}
                    />
                    <Can
                        I={Action.Delete}
                        this={subject(Subjects.TerrainModel, {
                            ...row.original,
                        })}
                    >
                        <DeleteButtonWithConfirm
                            variant="secondary"
                            className="ml-1"
                            onClick={() => performDelete(row.original.id)}
                        />
                    </Can>
                </div>
            ),
        },
    ];

    // Extract all extensions, flatten the array, and join them into a string
    const acceptedExtensions = filteredTerrainModelsTypeRules
        .map(rule => rule.ext)
        .flat()
        .map(ext => `.${ext}`)
        .join(',');

    const user = useSelector(selectLoggedInUser);


    return (
        <Container className="section">

            <div className="mb-3">
                <Row>
                    <Col xs={'auto'}>
                        <h1>{ObjectNames.terrainModels.en}</h1>
                    </Col>
                    <Col className="text-right">
                        <Can I={Action.Create} a={Subjects.TerrainModel}>

                            <FileUploadPopin title="Add model" callback={onFileUpload} maxFiles={1} acceptedExtensions={acceptedExtensions} />

                        </Can>
                        {user?.role == 'ADMIN' && <button className="ml-2 btn btn-secondary" onClick={() => setShowOldTerrainModelCreator(showOldTerrainModelCreator => !showOldTerrainModelCreator)}><FontAwesomeIcon
                            icon={faEllipsisH}
                        /></button>
                        }

                    </Col>
                </Row>
            </div>
            <ErrorBar
                errorMessage={errorMessage}
                onDismiss={() => setErrorMessage("")}
            />
            {showOldTerrainModelCreator && <Can I={Action.Create} a={Subjects.Terrains}>
                <>
                    <TerrainModelCreator />
                </>
            </Can>}

            <TableWithPagination
                columns={columns}
                data={terrainModels?.results || []}
                showPublicObjectsToggle={true}
                fetchData={(
                    pageIndexToFetch: number,
                    pageSizeToFetch: number,
                    filterToFetch: string,
                    sortToFetch: string,
                    showPublicObjectsToFetch: boolean
                ) => {
                    setPageIndex(pageIndexToFetch);
                    setPageSize(pageSizeToFetch);
                    setFilter(filterToFetch);
                    setSort(sortToFetch);
                    setShowPublicObjects(showPublicObjectsToFetch);
                }}
                loading={isFetching}
                itemCount={terrainModels?.total || 0}
                enableFiltering={true}
                defaultSort="createdAt:desc"
                sortableProps={[
                    { name: 'Name', accessor: 'name' },
                    { name: 'Visibility', accessor: 'visibility' },
                    { name: 'Type', accessor: 'meshDataSourceType' },
                    { name: 'Created at', accessor: 'createdAt' }
                ]}
            />
        </Container>
    );
};
