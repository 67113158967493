import { subject } from "@casl/ability";
import { Subjects } from "@shared/auth/src";
import { FC, ReactNode, useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { Column } from "react-table";
import { useGetAllLayersQuery } from "src/api/LayerApi";
import { Action } from "src/api/Permissions";
import { TerrainLayer } from "src/api/generated.api";
import { Can } from "src/casl/Can";
import TableSimple from "src/components/tables/TableSimple";
import TableWithPagination from "src/components/tables/TableWithPagination";
import { teamContext } from "../teams/context/team-context-provider";

export type TerrainLayersTableProps = {
    onRowActionClicked?: (layer: TerrainLayer) => void;
    action?: ReactNode;
};

export const TerrainLayersTableSelector: FC<TerrainLayersTableProps> = ({
    onRowActionClicked,
    action,
}) => {
    const { currentTeam } = useContext(teamContext);
    const [filter, setFilter] = useState("");
    const [sort, setSort] = useState("");

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(0);

    const layerQueryProps = {
        offset: pageIndex * pageSize,
        limit: pageSize,
        filter: filter,
        sort: sort,
        teamId: currentTeam ? currentTeam.id : "",
        showPublicObjects: true
    }

    const { data, isFetching } = useGetAllLayersQuery(
        layerQueryProps,
        { refetchOnMountOrArgChange: true }
    );

    const actionColumn =
        onRowActionClicked && action
            ? [
                {
                    Header: "Action",
                    Cell: ({ row }: { row: { original: TerrainLayer } }) => (
                        <Can
                            I={Action.Read}
                            this={subject(Subjects.TerrainLayer, {
                                ...row.original,
                            })}
                        >
                            <Button
                                onClick={() =>
                                    onRowActionClicked(row.original)
                                }
                            >
                                {action}
                            </Button>
                        </Can>
                    ),
                },
            ]
            : [];

    const columns: Column<TerrainLayer>[] = [
        {
            Header: "Layer name",
            Cell: ({ row }: { row: { original: TerrainLayer } }) => (
                <>{row.original.name}</>
            ),
        },
        {
            Header: "Type",
            Cell: ({ row }: { row: { original: TerrainLayer } }) => (
                <>{row.original.managementMode}</>
            ),
        },
        {
            Header: "Url",
            Cell: ({ row }: { row: { original: TerrainLayer } }) => (
                <>{row.original.url}</>
            ),
        },
        ...actionColumn,
    ];

    return (
        <div>
            <TableWithPagination
                columns={columns}
                data={data?.results || []}
                fetchData={(
                    pageIndexToFetch: number,
                    pageSizeToFetch: number,
                    filterToFetch: string,
                    sortToFetch: string
                ) => {
                    setPageIndex(pageIndexToFetch);
                    setPageSize(pageSizeToFetch);
                    setFilter(filterToFetch);
                    setSort(sortToFetch);
                }}
                loading={isFetching}
                itemCount={data?.total || 0}
                enableFiltering={true}
            />
        </div>
    );
};
