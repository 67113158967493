import { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { selectAuthStatus } from "./authSlice";
import { useGetAuthMethods, useLocalLoginMutation } from "src/api/AuthApi";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Form as FormikForm, Formik, FieldArray, Field } from "formik";

import * as Yup from "yup";
import { AuthMethodsDto, LoginUserDto, useAcceptTeamInvitationMutation, useValidAcceptInviteLinkMutation, useValidPasswordResetLinkMutation } from "src/api/generated.api";
import { FkStackedTextInput } from "src/components/FkStackedTextInput";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import axios from "axios";
import { LocalPasswordSchema } from "../users/AddAuthProvider";
import { inView } from "ol/layer/Layer";
import RoutesDef from "src/routes/RoutesDef";

interface Props {
    clientMode?: Boolean;
}

export function CreateAccount({ clientMode }: Props) {
    let { userId, timestamp, hash } = useParams();
    const { ObjectNames, Sentences } = useContext(LangContext);
    const authStatus = useSelector(selectAuthStatus);
    const navigate = useNavigate();
    const location = useLocation();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");

    const [validLink] = useValidAcceptInviteLinkMutation();
    const [acceptInvite] = useAcceptTeamInvitationMutation();

    useEffect(() => {
        if (userId && timestamp && hash) {
            validLink({ id: parseInt(userId), validInvitationDto: { timestamp: parseInt(timestamp), hash: decodeURIComponent(hash) } }).unwrap().then((value) => {
                setIsValid(value.isValid);
                if (isValid) {
                    setEmail(value.email);
                }
            });
        }
    }, [isValid])

    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = async (values: any, formikHelpers: any) => {
        try {
            console.log(userId, timestamp, hash)
            if (userId && timestamp && hash) {
                const user = await acceptInvite({ id: parseInt(userId), inviteUpdateLocalUserDto: { timestamp: parseInt(timestamp), hash: decodeURIComponent(hash), firstName: values.firstName, lastName: values.lastName, password: values.password, username: values.username } }).unwrap();
                if (user) {
                    setIsConfirmed(true);
                }
            }
        } catch (error) { }
    };


    if (authStatus === "LOGGED_IN" && clientMode) {
        return <Navigate replace to={RoutesDef.UNITY_HOME} />;
    }
    else if (authStatus === "LOGGED_IN") {
        return <Navigate replace to={RoutesDef.HOME} />;
    }
    else {
        return (

            <>
                <>
                    {isConfirmed && (<Row className="bg ">
                        <Col className="my-auto justify-content-center">

                            <Container style={{ width: 450 }}>
                                <Card>
                                    <Card.Header className="text-center">
                                        <h3>Welcome to VRExplorer</h3>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                Your account is created !
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col>
                                                <Button href={RoutesDef.LOGIN}>
                                                    Log in
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Container>
                        </Col>
                    </Row>)}
                </>
                {isValid && !isConfirmed ? (
                    <Row className="bg ">
                        <Col className="my-auto justify-content-center">
                            <Container style={{ width: 450 }}>
                                <Card>
                                    <Card.Header className="text-center">
                                        <h3>You've been invited to VRExplorer</h3>
                                    </Card.Header>
                                    <Card.Body>
                                        <div>
                                            <ErrorBar
                                                errorMessage={errorMessage}
                                            />
                                            <Formik
                                                initialValues={{
                                                    email,
                                                    fisrtName: "",
                                                    username: "",
                                                    lastName: "",
                                                    password: "",
                                                }}
                                                validationSchema={Yup.object({
                                                    email: Yup.string().email('Invalid email').required('Required'),
                                                    username: Yup.string().min(6, 'Username must be at least 6 characters')
                                                        .max(16, 'Username must be at most 16 characters')
                                                        .required('Username is required'),
                                                    password: LocalPasswordSchema,
                                                    confpassword: Yup.string()
                                                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                                                })}
                                                onSubmit={onSubmit}
                                                enableReinitialize={true}
                                            >
                                                {(formik) => (
                                                    <FormikForm>
                                                        <div className="mb-4">
                                                            Email : {email}
                                                        </div>
                                                        <FkStackedTextInput
                                                            label="User Name"
                                                            type="text"
                                                            name="username"
                                                            placeholder="myusername"
                                                        />
                                                        <FkStackedTextInput
                                                            label="First Name"
                                                            type="text"
                                                            name="firstName"
                                                            placeholder="John"
                                                        />
                                                        <FkStackedTextInput
                                                            label="Last Name"
                                                            type="text"
                                                            name="lastName"
                                                            placeholder="Doe"
                                                        />
                                                        <FkStackedTextInput
                                                            label="Password *"
                                                            type="password"
                                                            name="password"
                                                            placeholder="something super secret"
                                                        />
                                                        <FkStackedTextInput
                                                            label="Confirm Password *"
                                                            type="password"
                                                            name="confpassword"
                                                            placeholder="something super secret"
                                                        />

                                                        <Button
                                                            type="submit"
                                                            variant="primary"
                                                            block
                                                        >
                                                            Join your team
                                                        </Button>
                                                    </FormikForm>
                                                )}
                                            </Formik>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Container>
                        </Col>
                    </Row>
                ) : (<Row className="bg ">
                    <Col className="my-auto justify-content-center">

                        <Container style={{ width: 450 }}>
                            <Card>
                                <Card.Header className="text-center">
                                    <h3>VRExplorer</h3>
                                </Card.Header>
                                <Card.Body>
                                    <div>
                                        Invalid or expired link.
                                    </div>
                                </Card.Body>
                            </Card>
                        </Container>
                    </Col>
                </Row>)}
            </>
        );
    }
}
