import { FC, useState, useContext, useEffect } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { convertApiErrorsToFormikErrors } from "src/helpers/ApiHelperFunctions";
import { Formik, Form as FormikForm } from "formik";
import { BootstrapTextArea } from "../../components/BootstrapFormComponents";
import * as Yup from "yup";
import PageSelector from "./PageSelector";
import PageEditor from "./PageEditor";
import {
    useActivitiesAddUser,
    useChangeTeam,
    useChangeVisibility,
    useCreateActivityMutation,
    useCreateActivitySlideMutation,
    useGetActivityQuery,
    useUpdateActivityMutation,
} from "src/api/ActivityApi";
import ErrorBar from "src/components/ErrorBar";
import { SlideSummaryDto } from "src/api/generated.api";
import { LangContext } from "src/lang/lang";
import { FkStackedTextInput } from "src/components/FkStackedTextInput";
import { DocumentAclEntriesEditor } from "../document-access-control/document-acl-entries-editor";
import { Action, Subjects } from "src/api/Permissions";
import { DocumentTeamEditIfCan } from "../document-mgmt/document-team-edit-if-can";
import { DocumentVisibilityEditorIfCan } from "../document-access-control/document-visibility-editor-if-can";
import { DocumentAclDebugger } from "../document-access-control/document-acl-debugger";
import { ModalDocumentACLEditor } from "../document-access-control/modal-document-acl-editor";
import { useAbility } from "@casl/react";
import { AbilityContext } from "src/casl/Can";
import { subject } from "@casl/ability";

interface EditActivityProps {
    activityId: number;
    isEdit?: boolean;
    onSaved?: () => void;
}

export const EditActivity: FC<EditActivityProps> = ({
    activityId,
    isEdit,
    onSaved,
}) => {
    const { ObjectNames, Sentences } = useContext(LangContext);
    const [editable, setEditable] = useState(false);
    const [currentPage, setCurrentPage] = useState<SlideSummaryDto>();
    const [error, setError] = useState<string>("");

    const { data } = useGetActivityQuery({ activityId });
    const abilities = useAbility(AbilityContext);
    const [updateActivity] = useUpdateActivityMutation();
    const [createActivity] = useCreateActivityMutation();
    const [createSlide] = useCreateActivitySlideMutation();
    const [upsertMembership] = useActivitiesAddUser();
    const [changeTeam] = useChangeTeam();
    const [changeVisibility] = useChangeVisibility();
    async function onFormikSubmit(values: any, formikBag: any) {
        formikBag.setSubmitting(true);
        try {
            if (isEdit) {
                await updateActivity({
                    activityId,
                    updateActivityDto: {
                        name: values["name"],
                        description: values["description"],
                    },
                }).unwrap();
            } else {
                await createActivity({
                    createActivityDto: {
                        name: values.name,
                        teamId: values.teamId,
                    },
                }).unwrap();
            }
            if (onSaved) onSaved();
        } catch (e) {
            const errors = convertApiErrorsToFormikErrors(e);
            formikBag.setErrors(errors);
        }
    }

    const validationSchema = Yup.object({
        name: Yup.string().required(),
        description: Yup.string(),
    });

    function addSlide() {
        createSlide({
            activityId,
            createSlideDto: {
                title: `${ObjectNames.slide.en} ${(data?.slides?.length || 0) + 1
                    }`,
            },
        })
            .unwrap()
            .then((newSlide) => setCurrentPage(newSlide))
            .catch((reason) => setError(JSON.stringify(reason)));
    }

    function onDeletePage(pageId: number) {
        if (currentPage && currentPage.id === pageId) {
            setCurrentPage(undefined);
        }
    }

    useEffect(() => {
        setEditable(
            data !== undefined &&
            abilities.can(
                Action.Update,
                subject(Subjects.Activities, { ...data }),
            ),
        );
    }, [data, abilities]);
    return (
        <div>
            <ErrorBar errorMessage={error} />
            {data && (
                <>
                    <Formik
                        initialValues={data}
                        validationSchema={validationSchema}
                        onSubmit={onFormikSubmit}
                    >
                        {(formik) => (
                            <FormikForm
                                onSubmit={formik.handleSubmit}
                                method="post"
                            >
                                <Row className="section mb-3">
                                    <Col>
                                        <h1>{Sentences.activityEdit.en}</h1>
                                        <ModalDocumentACLEditor
                                            document={data}
                                            onVisibilityUpdate={
                                                changeVisibility
                                            }
                                            documentType={Subjects.Activities}
                                            onTeamChanged={changeTeam}
                                            objWithTeam={data}
                                            documentId={`${data.id}`}
                                            teamId={data.teamId}
                                            onUpsert={(dto) =>
                                                upsertMembership({
                                                    id: dto.documentId,
                                                    upsertDocumentMembershipDto:
                                                        dto,
                                                }).unwrap()
                                            }
                                        />
                                    </Col>
                                    {editable && (
                                        <Col
                                            md="auto"
                                            className="align-items-end d-flex"
                                        >
                                            <Button type="submit">
                                                {Sentences.save.en}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                                <div className="section">
                                    <FkStackedTextInput
                                        label={Sentences.activityName.en}
                                        name="name"
                                        placeholder={Sentences.activityName.en}
                                        disabled={!editable}
                                    />
                                    <BootstrapTextArea
                                        label="Description"
                                        name="description"
                                        rows={7}
                                        placeholder={
                                            Sentences.activityDescription.en
                                        }
                                        disabled={!editable}
                                    />
                                </div>
                            </FormikForm>
                        )}
                    </Formik>
                </>
            )}
            <Row className="section">
                <Col xs={12} md={2} id="sidebar-wrapper">
                    <PageSelector
                        activityId={activityId}
                        currentPage={currentPage}
                        onPageSelected={(page) => setCurrentPage(page)}
                        onAddClick={addSlide}
                        editable={editable}
                    />
                </Col>

                <Col xs={12} md={10} id="page-content-wrapper">
                    <Card>
                        <Card.Header>{Sentences.slideEditor.en}</Card.Header>
                        <Card.Body>
                            {currentPage && currentPage.id !== undefined ? (
                                <PageEditor
                                    pageId={currentPage.id}
                                    onDeletedPage={onDeletePage}
                                    editable={editable}
                                />
                            ) : (
                                <div>{Sentences.slideNoSelected.en}.</div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
