import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container, Spinner, Row, Col, Form } from 'react-bootstrap';

// Define types for the response structure
interface BoundingBox {
    min: number[];
    max: number[];
}

interface Attribute {
    name: string;
    description: string;
    size: number;
    numElements: number;
    elementSize: number;
    type: string;
    min: number[];
    max: number[];
    scale: number[];
    offset: number[];
}

interface PointCloudData {
    version: string;
    name: string;
    description: string;
    points: number;
    projection: string;
    hierarchy: {
        firstChunkSize: number;
        stepSize: number;
        depth: number;
    };
    offset: number[];
    scale: number[];
    spacing: number;
    boundingBox: BoundingBox;
    encoding: string;
    attributes: Attribute[];
}

interface PointCloudMetadataProps {
    meshFilename: string;
    callback: (message: string) => void;
}

export const PointCloudMetadata: React.FC<PointCloudMetadataProps> = ({ meshFilename, callback }) => {
    const [data, setData] = useState<PointCloudData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!meshFilename) {
            console.error('No API URL provided.');
            return;
        }

        setLoading(true);
        const uri = '/' + meshFilename.replace('tileset.json', 'metadata.json');
        axios.get(uri)
            .then(response => {
                console.log('Response:', response.data);
                setData(response.data);
                const isValid = checkLasValidity(response.data);
                if (!isValid) {
                    callback('Error: The uploaded LAS/LAZ file does not contain valid "rgb" or "intensity" attributes. Please ensure your file includes at least one of these attributes for proper visualization.')
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [meshFilename]);

    if (loading) {
        return (
            <Container className="text-center mt-5">
                <Spinner animation="border" role="status" />
                <p>Loading metadata...</p>
            </Container>
        );
    }

    if (!data) {
        return (
            <Container className="text-center mt-5">
                <p>No pointcloud metadata available</p>
            </Container>
        );
    }

    const checkLasValidity = (metadata: any) => {
        let isValid = false;
        metadata.attributes.forEach((attr: any) => {
            if (attr.type.toLowerCase() === 'red' || attr.type.toLowerCase() === 'r' || attr.type.toLowerCase() === 'rgb' || attr.type.toLowerCase() === 'intensity') {
                isValid = true;
            }
        });
        return isValid;
    }

    return (
        <div className="mt-5">
            <h3 className="mb-4">Point Cloud Details</h3>

            <Row className="mb-4">
                <Col>
                    <h4>General Information</h4>
                    <p><strong>Name:</strong> {data.name}</p>
                    <p><strong>Version:</strong> {data.version}</p>
                    <p><strong>Points:</strong> {data.points.toLocaleString()}</p>
                    <p><strong>Spacing:</strong> {data.spacing}</p>
                </Col>
                <Col>
                    <h4>Bounding Box</h4>
                    <p><strong>Min:</strong> {data.boundingBox.min.join(', ')}</p>
                    <p><strong>Max:</strong> {data.boundingBox.max.join(', ')}</p>
                </Col>
            </Row>

            <h4>Attributes</h4>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Elements</th>
                        <th>Min</th>
                        <th>Max</th>
                    </tr>
                </thead>
                <tbody>
                    {data.attributes.map((attr, index) => (
                        <tr key={index}>
                            <td>{attr.name}</td>
                            <td>{attr.type}</td>
                            <td>{attr.size}</td>
                            <td>{attr.numElements}</td>
                            <td>{attr.min.join(', ')}</td>
                            <td>{attr.max.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

        </div>
    );
};
