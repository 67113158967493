
import {
    FC,
    useContext,
    useEffect,
    useState,
} from "react";

import { Action, Subjects } from "@shared/auth/src";
import { useParams } from "react-router";
import { AbilityContext } from "src/casl/Can";
import ErrorBar from "src/components/ErrorBar";
import { LangContext } from "src/lang/lang";
import { RenderMarkdownUnity } from './render-markdown.unity';

export const MarkownPageUnity: FC<unknown> = () => {
    const { Sentences } = useContext(LangContext);

    const { slideId } = useParams<{ slideId: string }>();

    const [id, setId] = useState<number>();
    const ability = useContext(AbilityContext);
    useEffect(
        () => (slideId ? setId(parseInt(slideId)) : setId(undefined)),
        [slideId],
    );

    return (
        <>
            {ability.can(Action.Read, Subjects.Slide) ? (
                id ? (
                    <RenderMarkdownUnity slideId={id} />
                ) : (
                    <div>Slide not found</div>
                )
            ) : (
                <ErrorBar errorMessage={Sentences.pageNotAllowed.en} />
            )}
        </>
    );
};

